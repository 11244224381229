import React from 'react'
import Layout from "../components/Layout"

export default function AboutPage() {
  return (
    <Layout>
      <div>
        <h1> About page </h1>
        <p>This the about page </p>
      </div>
    </Layout>
  )
}
